import { UPLOADED, UPLOAD_FAILED } from '../../actions/types/types';

const initialState = [];

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case UPLOADED:
          return {
            ...state,
            uploadedFile: payload
          };
        case UPLOAD_FAILED:
          return {
            ...state,
            uploadedFile: null
          };
        default:
          return state;
    }
}