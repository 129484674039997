import { LOGIN_FAIL, LOGIN_SUCCESS, USER_LOADED, USER_LOAD_FAILED, LOGOUT, PASSWORD_CHANGED, PASSWORD_CHANGE_FAILED, EMAIL_SENT, EMAIL_SEND_FAILED, EMAIL_LOADED, EMAIL_LOAD_FAILED, SERVER_TIME_LOADED, SERVER_TIME_LOAD_FAILED } from '../../actions/types/types';

const initialState = [];

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case USER_LOADED:
          return {
            ...state,
            isAuthenticated: true,
            loading: false,
            user: payload,
            error: null
          };
        case LOGIN_SUCCESS:
          localStorage.setItem('token', payload.token);
          localStorage.setItem('database', payload.database);
          return {
            ...state,
            isAuthenticated: true,
            loading: false,
            error: null
          };
        case LOGIN_FAIL:
          localStorage.removeItem('token');
          localStorage.removeItem('database');
          return {
            ...state,
            database: null,
            token: null,
            isAuthenticated: false,
            loading: false,
            error: payload
          };
        case USER_LOAD_FAILED:
        case LOGOUT:
          localStorage.removeItem('token');
          localStorage.removeItem('database');
          return {
            ...state,
            database: null,
            token: null,
            isAuthenticated: false,
            loading: false,
            error: null
          };
        case PASSWORD_CHANGED:
          return {
            ...state,
            isChanged: true
          };
        case PASSWORD_CHANGE_FAILED:
          return {
            ...state,
            isChanged: false
          };
        case EMAIL_SENT:
          return {
            ...state,
            isSent: true
          };
        case EMAIL_SEND_FAILED:
          return {
            ...state,
            isSent: false
          };
        case EMAIL_LOADED:
          return {
            ...state,
            loadedEmail: payload
          };
        case EMAIL_LOAD_FAILED:
          return {
            ...state,
            loadedEmail: null
          };
        case SERVER_TIME_LOADED:
          return {
            ...state,
            serverTime: payload
          };
        case SERVER_TIME_LOAD_FAILED:
          return {
            ...state,
            serverTime: null
          };
        default:
          return state;
    }
}