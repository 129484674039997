// Event Action Types
export const ADD_EVENT = "ADD_EVENT";

// Chat Action Types
export const HANDLE_FILTER_USER = "HANDLE_FILTER_USER";
export const HANDLE_SELECT_USER = "HANDLE_SELECT_USER";
export const HANDLE_SEND_MESSAGE = "HANDLE_SEND_MESSAGE";

// Alert Types
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Login Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

// Logout Types
export const LOGOUT = "LOGOUT";

// Load Types
export const USER_LOADED = 'USER_LOADED';
export const USER_LOAD_FAILED = 'USER_LOAD_FAILED';

// Password Change Types
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';
export const PASSWORD_CHANGE_FAILED = 'PASSWORD_CHANGE_FAILED';

// Password Change Types
export const EMAIL_SENT = 'EMAIL_SENT';
export const EMAIL_SEND_FAILED = "EMAIL_SEND_FAILED";

// Email Load Types
export const EMAIL_LOADED = 'EMAIL_LOADED';
export const EMAIL_LOAD_FAILED = "EMAIL_LOAD_FAILED";

// Server Time Load Types
export const SERVER_TIME_LOADED = 'SERVER_TIME_LOADED';
export const SERVER_TIME_LOAD_FAILED = 'SERVER_TIME_LOAD_FAILED';

// Kid Management Types
export const KID_LOADED = 'KID_LOADED';
export const KID_LOAD_FAILED = 'KID_LOAD_FAILED';
export const KID_REMOVED = 'KID_REMOVED';
export const KID_REMOVE_FAILED = 'KID_REMOVE_FAILED';
export const KID_TOGGLED = 'KID_TOGGLED';
export const KID_TOGGLE_FAILED = 'KID_TOGGLE_FAILED';
export const KID_EDITED = 'KID_EDITED';
export const KID_EDIT_FAILED = 'KID_EDIT_FAILED';
export const KID_ADDED = 'KID_ADDED';
export const KID_ADD_FAILED = 'KID_ADD_FAILED';
export const PARENT_REMOVED = 'PARENT_REMOVED';
export const PARENT_REMOVE_FAILED = 'PARENT_REMOVE_FAILED';
export const PARENT_LOADED = 'PARENT_LOADED';
export const PARENT_LOAD_FAILED = 'PARENT_LOAD_FAILED';
export const EXCEL_FILE_LOADED = 'EXCEL_FILE_LOADED';
export const EXCEL_FILE_LOAD_FAILED = 'EXCEL_FILE_LOAD_FAILED';
export const EXCEL_FILE_UPLOADED = 'EXCEL_FILE_UPLOADED';
export const EXCEL_FILE_UPLOAD_FAILED = 'EXCEL_FILE_UPLOAD_FAILED';

// Emp Management Types
export const EMP_LOADED = 'EMP_LOADED';
export const EMP_LOAD_FAILED = 'EMP_LOAD_FAILED';
export const EMP_REMOVED = 'EMP_REMOVED';
export const EMP_REMOVE_FAILED = 'EMP_REMOVE_FAILED';
export const EMP_TOGGLED = 'EMP_TOGGLED';
export const EMP_TOGGLE_FAILED = 'EMP_TOGGLE_FAILED';
export const EMP_ADDED = 'EMP_ADDED';
export const EMP_ADD_FAILED = 'EMP_ADD_FAILED';
export const EMP_EDITED = 'EMP_EDITED';
export const EMP_EDIT_FAILED = 'EMP_EDIT_FAILED';

// Bus Management Types
export const BUS_LOADED = 'BUS_LOADED';
export const BUS_LOAD_FAILED = 'BUS_LOAD_FAILED';
export const BUS_REMOVED = 'BUS_REMOVED';
export const BUS_REMOVE_FAILED = 'BUS_REMOVE_FAILED';
export const BUS_ADDED = 'BUS_ADDED';
export const BUS_ADD_FAILED = 'BUS_ADD_FAILED';
export const BUS_EDITED = 'BUS_EDITED';
export const BUS_EDIT_FAILED = 'BUS_EDIT_FAILED';

// Bus Management Types
export const STOP_LOADED = 'STOP_LOADED';
export const STOP_LOAD_FAILED = 'STOP_LOAD_FAILED';
export const STOP_REMOVED = 'STOP_REMOVED';
export const STOP_REMOVE_FAILED = 'STOP_REMOVE_FAILED';
export const STOP_ADDED = 'STOP_ADDED';
export const STOP_ADD_FAILED = 'STOP_ADD_FAILED';
export const STOP_EDITED = 'STOP_EDITED';
export const STOP_EDIT_FAILED = 'STOP_EDIT_FAILED';

// Class Management Types
export const CLASS_LOADED = 'CLASS_LOADED';
export const CLASS_LOAD_FAILED = 'CLASS_LOAD_FAILED';
export const CLASS_REMOVED = 'CLASS_REMOVED';
export const CLASS_REMOVE_FAILED = 'CLASS_REMOVE_FAILED';
export const CLASS_ADDED = 'CLASS_ADDED';
export const CLASS_ADD_FAILED = 'CLASS_ADD_FAILED';
export const CLASS_EDITED = 'CLASS_EDITED';
export const CLASS_EDIT_FAILED = 'CLASS_EDIT_FAILED';
export const KID_FOR_CLASS_LOADED = 'KID_FOR_CLASS_LOADED';
export const KID_FOR_CLASS_LOAD_FAILED = 'KID_FOR_CLASS_LOAD_FAILED';
export const KID_FOR_CLASS_TOGGLED = 'KID_FOR_CLASS_TOGGLED';
export const KID_FOR_CLASS_TOGGLE_FAILED = 'KID_FOR_CLASS_TOGGLE_FAILED';

// Route Management Types
export const ROUTE_LOADED = 'ROUTE_LOADED';
export const ROUTE_LOAD_FAILED = 'ROUTE_LOAD_FAILED';
export const STOP_FOR_ROUTE_LOADED = 'STOP_FOR_ROUTE_LOADED';
export const STOP_FOR_ROUTE_LOAD_FAILED = 'STOP_FOR_ROUTE_LOAD_FAILED';
export const STOP_FOR_ROUTE_TOGGLED = 'STOP_FOR_ROUTE_TOGGLED';
export const STOP_FOR_ROUTE_TOGGLE_FAILED = 'STOP_FOR_ROUTE_TOGGLE_FAILED';
export const ROUTE_REMOVED = 'ROUTE_REMOVED';
export const ROUTE_REMOVE_FAILED = 'ROUTE_REMOVE_FAILED';
export const ROUTE_EDITED = 'ROUTE_EDITED';
export const ROUTE_EDIT_FAILED = 'ROUTE_EDIT_FAILED';
export const ROUTE_ADDED = 'ROUTE_ADDED';
export const ROUTE_ADD_FAILED = 'ROUTE_ADD_FAILED';

// Upload Types
export const UPLOADED = 'UPLOADED';
export const UPLOAD_FAILED = 'UPLOAD_FAILED';

// Attendance Types
export const ATTENDANCES_LOADED = 'ATTENDANCES_LOADED';
export const ATTENDANCES_LOAD_FAILED = 'ATTENDANCES_LOAD_FAILED';
export const ATTENDANCE_TOGGLED = 'ATTENDANCE_TOGGLED';
export const ATTENDANCE_TOGGLE_FAILED = 'ATTENDANCE_TOGGLE_FAILED';
export const CLASSES_FOR_SEARCH_LOADED = 'CLASSES_FOR_SEARCH_LOADED';
export const CLASSES_FOR_SEARCH_LOAD_FAILED = 'CLASSES_FOR_SEARCH_LOAD_FAILED';
export const ATTENDANCE_HISTORY_LOADED = 'ATTENDANCE_HISTORY_LOADED';
export const ATTENDANCE_HISTORY_LOAD_FAILED = 'ATTENDANCE_HISTORY_LOAD_FAILED';
export const CLASS_HISTORY_LOADED = 'CLASS_HISTORY_LOADED';
export const CLASS_HISTORY_LOAD_FAILED = 'CLASS_HISTORY_LOAD_FAILED';
export const NOTIFICATION_LOADED = 'NOTIFICATION_LOADED';
export const NOTIFICATION_LOAD_FAILED = 'NOTIFICATION_LOAD_FAILED';
export const NOTIFICATION_SAVED = 'NOTIFICATION_SAVED';
export const NOTIFICATION_SAVE_FAILED = 'NOTIFICATION_SAVE_FAILED';

// Onboard Types
export const ROUTE_FOR_SEARCH_LOADED = 'ROUTE_FOR_SEARCH_LOADED';
export const ROUTE_FOR_SEARCH_LOAD_FAILED = 'ROUTE_FOR_SEARCH_LOAD_FAILED';
export const BOARDING_HISTORY_LOADED = 'BOARDING_HISTORY_LOADED';
export const BOARDING_HISTORY_LOAD_FAILED = 'BOARDING_HISTORY_LOAD_FAILED';