// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
import calenderReducer from "./calenderReducer";
import emailReducer from "./email/";
// import chatReducer from "./chatReducer";
import chatReducer from "./chat/";
import contactsReducer from "./contacts/";
import todoReducer from "./todo/";
import customizer from "./customizer/";
import alert from './alert/alert';
import login from './login/login';
import kid from './kid/kid';
import emp from './emp/emp';
import bus from './bus/bus';
import stop from './stop/stop';
import class1 from './class/class';
import route from './route/route';
import upload from './upload/upload';
import attendance from './attendance/attendance';
import onboard from './onboard/onboard';

import { reducer as toastrReducer } from "react-redux-toastr";

const rootReducer = combineReducers({
   calender: calenderReducer,
   emailApp: emailReducer,
   contactApp: contactsReducer,
   todoApp: todoReducer,
   toastr: toastrReducer, // <- Mounted at toastr.
   chatApp: chatReducer,
   customizer: customizer,
   alert: alert,
   login: login,
   kid: kid,
   emp: emp,
   bus: bus,
   stop: stop,
   class: class1,
   route: route,
   upload: upload,
   attendance: attendance,
   onboard: onboard,
});

export default rootReducer;
