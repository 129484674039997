import { ROUTE_LOADED, ROUTE_LOAD_FAILED, STOP_FOR_ROUTE_LOADED, STOP_FOR_ROUTE_LOAD_FAILED, STOP_FOR_ROUTE_TOGGLED, STOP_FOR_ROUTE_TOGGLE_FAILED, ROUTE_REMOVED, ROUTE_REMOVE_FAILED, ROUTE_ADDED, ROUTE_ADD_FAILED, ROUTE_EDITED, ROUTE_EDIT_FAILED } from '../../actions/types/types';

const initialState = [];

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ROUTE_LOADED:
          return {
            ...state,
            routes: payload
          };
        case ROUTE_LOAD_FAILED:
          return {
            ...state,
            routes: null
          };
        case STOP_FOR_ROUTE_LOADED:
          return {
            ...state,
            stopsForRoute: payload
          };
        case STOP_FOR_ROUTE_LOAD_FAILED:
          return {
            ...state,
            stopsForRoute: null
          };
        case STOP_FOR_ROUTE_TOGGLED:
          return {
            ...state,
            toggledStopsForRoute: payload
          };
        case STOP_FOR_ROUTE_TOGGLE_FAILED:
          return {
            ...state,
            toggledStopsForRoute: null
          };
        case ROUTE_REMOVED:
          return {
            ...state,
            removedRoute: payload
          };
        case ROUTE_REMOVE_FAILED:
          return {
            ...state,
            removedRoute: null
          };
        case ROUTE_ADDED:
          return {
            ...state,
            editedRoute: payload
          };
        case ROUTE_ADD_FAILED:
          return {
            ...state,
            editedRoute: null
          };
        case ROUTE_EDITED:
          return {
            ...state,
            editedRoute: payload
          };
        case ROUTE_EDIT_FAILED:
          return {
            ...state,
            editedRoute: null
          };
        default:
          return state;
    }
}