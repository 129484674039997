import { EMP_LOADED, EMP_LOAD_FAILED, EMP_REMOVED, EMP_REMOVE_FAILED, EMP_TOGGLED, EMP_TOGGLE_FAILED, EMP_EDITED, EMP_EDIT_FAILED, EMP_ADDED, EMP_ADD_FAILED, } from '../../actions/types/types';

const initialState = [];

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case EMP_LOADED:
          return {
            ...state,
            emps: payload
          };
        case EMP_LOAD_FAILED:
          return {
            ...state,
            emps: null
          };
        case EMP_REMOVED:
          return {
            ...state,
            removedEmps: payload
          };
        case EMP_REMOVE_FAILED:
          return {
            ...state,
            removedEmps: null
          };
        case EMP_TOGGLED:
          return {
            ...state,
            toggledEmps: payload
          };
        case EMP_TOGGLE_FAILED:
          return {
            ...state,
            toggledEmps: null
          };
        case EMP_ADDED:
          return {
            ...state,
            editedEmp: payload
          };
        case EMP_ADD_FAILED:
          return {
            ...state,
            editedEmp: null
          };
        case EMP_EDITED:
          return {
            ...state,
            editedEmp: payload
          };
        case EMP_EDIT_FAILED:
          return {
            ...state,
            editedEmp: null
          };
        default:
            return state;
    }
}