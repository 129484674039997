import { STOP_LOADED, STOP_LOAD_FAILED, STOP_REMOVED, STOP_REMOVE_FAILED, STOP_ADDED, STOP_ADD_FAILED, STOP_EDITED, STOP_EDIT_FAILED } from '../../actions/types/types';

const initialState = [];

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case STOP_LOADED:
          return {
            ...state,
            stops: payload
          };
        case STOP_LOAD_FAILED:
          return {
            ...state,
            stops: null
          };
        case STOP_REMOVED:
          return {
            ...state,
            removedStops: payload
          };
        case STOP_REMOVE_FAILED:
          return {
            ...state,
            removedStops: null
          };
        case STOP_EDITED:
          return {
            ...state,
            editedStop: payload
          };
        case STOP_EDIT_FAILED:
          return {
            ...state,
            editedStop: null
          };
        case STOP_ADDED:
          return {
            ...state,
            editedStop: payload
          };
        case STOP_ADD_FAILED:
          return {
            ...state,
            editedStop: null
          };
        default:
          return state;
    }
}