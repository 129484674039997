import { CLASSES_FOR_SEARCH_LOADED, CLASSES_FOR_SEARCH_LOAD_FAILED, ATTENDANCE_HISTORY_LOADED, ATTENDANCE_HISTORY_LOAD_FAILED, CLASS_HISTORY_LOADED, CLASS_HISTORY_LOAD_FAILED, NOTIFICATION_LOADED, NOTIFICATION_LOAD_FAILED, NOTIFICATION_SAVED, NOTIFICATION_SAVE_FAILED, ATTENDANCES_LOADED, ATTENDANCES_LOAD_FAILED, ATTENDANCE_TOGGLED, ATTENDANCE_TOGGLE_FAILED } from '../../actions/types/types';

const initialState = [];

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CLASSES_FOR_SEARCH_LOADED:
          return {
            ...state,
            loadedClassesForSearch: payload
          };
        case CLASSES_FOR_SEARCH_LOAD_FAILED:
          return {
            ...state,
            loadedClassesForSearch: null
          };
        case ATTENDANCE_HISTORY_LOADED:
          return {
            ...state,
            loadedAttendanceHistory: payload
          };
        case ATTENDANCE_HISTORY_LOAD_FAILED:
          return {
            ...state,
            loadedAttendanceHistory: null
          };
        case CLASS_HISTORY_LOADED:
          return {
            ...state,
            loadedClassHistory: payload
          };
        case CLASS_HISTORY_LOAD_FAILED:
          return {
            ...state,
            loadedClassHistory: null
          };
        case NOTIFICATION_LOADED:
          return {
            ...state,
            loadedNotifications: payload
          };
        case NOTIFICATION_LOAD_FAILED:
          return {
            ...state,
            loadedNotifications: null
          };
        case NOTIFICATION_SAVED:
          return {
            ...state,
            savedNotifications: payload
          };
        case NOTIFICATION_SAVE_FAILED:
          return {
            ...state,
            savedNotifications: null
          };
        case ATTENDANCES_LOADED:
          return {
            ...state,
            loadedAttendances: payload
          };
        case ATTENDANCES_LOAD_FAILED:
          return {
            ...state,
            loadedAttendances: null
          }
        case ATTENDANCE_TOGGLED:
          return {
            ...state,
            toggledAttendance: payload
          }
        case ATTENDANCE_TOGGLE_FAILED:
          return {
            ...state,
            toggledAttendance: payload
          }
        default:
            return state;
    }
}