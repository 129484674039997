import { KID_LOADED, KID_LOAD_FAILED, KID_REMOVED, KID_REMOVE_FAILED, KID_TOGGLED, KID_TOGGLE_FAILED, KID_EDITED, KID_EDIT_FAILED, KID_ADDED, KID_ADD_FAILED, PARENT_REMOVED, PARENT_REMOVE_FAILED, PARENT_LOADED, PARENT_LOAD_FAILED, EXCEL_FILE_LOADED, EXCEL_FILE_LOAD_FAILED, EXCEL_FILE_UPLOADED, EXCEL_FILE_UPLOAD_FAILED } from '../../actions/types/types';

const initialState = [];

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case KID_LOADED:
          return {
            ...state,
            kids: payload
          };
        case KID_LOAD_FAILED:
          return {
            ...state,
            kids: null
          };
        case KID_REMOVED:
          return {
            ...state,
            removedKids: payload
          };
        case KID_REMOVE_FAILED:
          return {
            ...state,
            removedKids: null
          };
        case KID_TOGGLED:
          return {
            ...state,
            toggledKids: payload
          };
        case KID_TOGGLE_FAILED:
          return {
            ...state,
            toggledKids: null
          };
        case KID_EDITED:
          return {
            ...state,
            editedKid: payload
          };
        case KID_EDIT_FAILED:
          return {
            ...state,
            editedKid: null
          };
        case KID_ADDED:
          return {
            ...state,
            editedKid: payload
          };
        case KID_ADD_FAILED:
          return {
            ...state,
            editedKid: null
          };
        case PARENT_REMOVED:
          return {
            ...state,
            removedParent: payload
          };
        case PARENT_REMOVE_FAILED:
          return {
            ...state,
            removedParent: null
          };
        case PARENT_LOADED:
          return {
            ...state,
            loadedParent: payload
          };
        case PARENT_LOAD_FAILED:
          return {
            ...state,
            loadedParent: null
          };
        case EXCEL_FILE_LOADED:
          return {
            ...state,
            loadedExcelFile: payload
          }
        case EXCEL_FILE_LOAD_FAILED:
          return {
            ...state,
            loadedExcelFile: null
          }
        case EXCEL_FILE_UPLOADED:
          return {
            ...state,
            uploadedExcelFile: payload
          }
        case EXCEL_FILE_UPLOAD_FAILED:
          return {
            ...state,
            uploadedExcelFile: null
          }
        default:
          return state;
    }
}