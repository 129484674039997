import { CLASS_LOADED, CLASS_LOAD_FAILED, CLASS_REMOVED, CLASS_REMOVE_FAILED, CLASS_ADDED, CLASS_ADD_FAILED, CLASS_EDITED, CLASS_EDIT_FAILED, KID_FOR_CLASS_LOADED, KID_FOR_CLASS_LOAD_FAILED, KID_FOR_CLASS_TOGGLED, KID_FOR_CLASS_TOGGLE_FAILED } from '../../actions/types/types';

const initialState = [];

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CLASS_LOADED:
          return {
            ...state,
            classes: payload
          };
        case CLASS_LOAD_FAILED:
          return {
            ...state,
            classes: null
          };
        case CLASS_REMOVED:
          return {
            ...state,
            removedClass: payload
          };
        case CLASS_REMOVE_FAILED:
          return {
            ...state,
            removedClass: null
          };
        case CLASS_EDITED:
          return {
            ...state,
            editedClass: payload
          };
        case CLASS_EDIT_FAILED:
          return {
            ...state,
            editedClass: null
          };
        case CLASS_ADDED:
          return {
            ...state,
            editedClass: payload
          };
        case CLASS_ADD_FAILED:
          return {
            ...state,
            editedClass: null
          };
        case KID_FOR_CLASS_LOADED:
          return {
            ...state,
            kidsForClass: payload
          };
        case KID_FOR_CLASS_LOAD_FAILED:
          return {
            ...state,
            kidsForClass: null
          };
        case KID_FOR_CLASS_TOGGLED:
          return {
            ...state,
            toggledKidsForClass: payload
          };
        case KID_FOR_CLASS_TOGGLE_FAILED:
          return {
            ...state,
            toggledKidsForClass: null
          };
        default:
          return state;
    }
}