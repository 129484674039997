import { ROUTE_FOR_SEARCH_LOADED, ROUTE_FOR_SEARCH_LOAD_FAILED, BOARDING_HISTORY_LOADED, BOARDING_HISTORY_LOAD_FAILED } from '../../actions/types/types';

const initialState = [];

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ROUTE_FOR_SEARCH_LOADED:
          return {
            ...state,
            loadedRoutesForSearch: payload
          };
        case ROUTE_FOR_SEARCH_LOAD_FAILED:
          return {
            ...state,
            loadedRoutesForSearch: null
          };
        case BOARDING_HISTORY_LOADED:
          return {
            ...state,
            loadedBoardingHistory: payload
          };
        case BOARDING_HISTORY_LOAD_FAILED:
          return {
            ...state,
            loadedBoardingHistory: null
          };
        default:
            return state;
    }
}