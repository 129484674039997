import { BUS_LOADED, BUS_LOAD_FAILED, BUS_REMOVED, BUS_REMOVE_FAILED, BUS_ADDED, BUS_ADD_FAILED, BUS_EDITED, BUS_EDIT_FAILED } from '../../actions/types/types';

const initialState = [];

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case BUS_LOADED:
          return {
            ...state,
            buses: payload
          };
        case BUS_LOAD_FAILED:
          return {
            ...state,
            buses: null
          };
        case BUS_REMOVED:
          return {
            ...state,
            removedBuses: payload
          };
        case BUS_REMOVE_FAILED:
          return {
            ...state,
            removedBuses: null
          };
        case BUS_EDITED:
          return {
            ...state,
            editedBus: payload
          };
        case BUS_EDIT_FAILED:
          return {
            ...state,
            editedBus: null
          };
        case BUS_ADDED:
          return {
            ...state,
            editedBus: payload
          };
        case BUS_ADD_FAILED:
          return {
            ...state,
            editedBus: null
          };
        default:
          return state;
    }
}